export const keys = {
  ALPHANUMERIC: 'common.validations.alphanumeric',
  INVALID_DE_ADDRESS1: 'common.validations.invalid_de_address1',
  INVALID_EMAIL: 'common.validations.email',
  INVALID_PASSPORT_NUMBER: 'common.validations.invalid_passport_number',
  INVALID_SSN: 'common.validations.invalid_ssn',
  INVALID_USER_TAX_CODE: 'common.validations.invalid_user_tax_code',
  INVALID_ZIPCODE: 'account.components.address.errors.invalid_zipcode',
  MAX_LENGTH: 'common.validations.max_length',
  MAX_VALUE: 'common.validations.max_value',
  MIN_LENGTH: 'common.validations.min_length',
  MIN_VALUE: 'common.validations.min_value',
  NOT_IN_LIST: 'common.validations.not_in_list',
  NUMBER: 'common.validations.number',
  PASSWORDS_MATCH: 'common.validations.passwords_match',
  REQUIRED: 'common.validations.required',
  STRING_LENGTH: 'common.validations.string_length',
}
